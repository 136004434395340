import React from "react"
import styled from '@emotion/styled'
import { Link, navigate } from "gatsby";
import { Navbar, Nav, Button, Form, NavDropdown } from 'react-bootstrap';

const NavBarDiv = styled.div`
  .bg-none {
    @media (min-width: 992px) { 
      padding: 20px 20px;
    }
  }
  height: 60px;
  text-align: left;
`

const Logo = styled.img`
  width: 180px;
  height: 25px;
`

const goTo = (url) => () => navigate(url);

export const NavBar = (props) => <NavBarDiv>
  <Navbar expand="md" variant="dark" bg={props.dark ? "dark" : "none"}>
    <Navbar.Brand >
      <Link to="/">
        <Logo css={{marginBottom: "5px"}} src="/images/logo/logo-white.svg" />
      </Link></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Link to="/pricing"><Nav.Link href="/">Pricing</Nav.Link></Link>
        <NavDropdown title="Resources" id="basic-nav-dropdown">
          <NavDropdown.Item onClick={goTo("/mission")}>Our Mission</NavDropdown.Item>
          <NavDropdown.Item onClick={goTo("/nc-case-study")}>Case Study</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="/resources/crowdcall-presentation.pdf">Presentation</NavDropdown.Item>
          <NavDropdown.Item href="/resources/crowdcall-info.pdf">Campaign Handout</NavDropdown.Item>
          <NavDropdown.Item href="https://chrome.google.com/webstore/detail/crowdcall-chrome/nopbfadgbajdgfimkghkmfnoefngbgla?hl=en">Chrome Extension</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={goTo("/contact-us")}>Contact Us</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      <Form inline>
        <Button variant="outline-light" href="http://app.crowdcall.us">Login</Button>
      </Form>
    </Navbar.Collapse>
  </Navbar>
</NavBarDiv >