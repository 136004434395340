import React from "react"
import { Link } from "gatsby";
import '../styles/global.css';
import { TitleHeader } from "../components/title-header/titleHeader";
import { CrowdCallContainer } from "../components/global/global";
import Typed from 'react-typed';
import { Col, Row } from "react-bootstrap";
import { FeatureBox } from "../components/feature-box/featureBox";
import { TiltedAccentTitle } from "../components/tilted-title-accent/tilted-title-accent";
import { Button } from "react-bootstrap";
import { ContactFormWrapper } from "../components/contact/contactFormWrapper";
import { Footer } from "../components/footer/footer";
import { TestimonialAd } from "../components/testimonialAd/testimonialAd";
import { BrOnMobile } from "../components/mobile-line-break/brOnMobile";

export default () => <CrowdCallContainer>
  <TitleHeader title={<span>
    <Typed
      strings={[
        'Seamless',
        'Affordable',
        'Integrated',
        'Simple',
        'Innovative',
        'Progressive',
        'Incredible']}
      typeSpeed={40}
      backSpeed={50}
      cursorChar={"|"}
      backDelay={3000}
      loop /> Political <BrOnMobile />Phonebanking</span>} subtitle="CrowdCall is the best way to facilitate volunteer phonebanks for progressive political campaigns.">
        <div css={{marginTop: "50px"}}>
          <Button variant="light" onClick={() => {
            let el = document.getElementById("contact");
            el.scrollIntoView(true);
          }}>Start Today</Button>
          <Button variant="outline-light" css={{marginLeft: "30px"}} onClick={() => {
            let el = document.getElementById("what-is-crowdcall");
            el.scrollIntoView(true);
          }}>Learn More</Button>
        </div>
      </TitleHeader>
  <Row id="what-is-crowdcall" className="align-items-center" css={{marginLeft: "-15px", marginTop: "40px"}}>
    <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
      <img alt="A screenshot of CrowdCall integrating with OpenVPB" src="/images/vpb-screenshot.png" css={{width: '100%'}} />
    </Col>
    <Col xs={{span: 10, order: 1, offset: 1}} md={{span: 4, order: 1, offset: 1}}>
        <FeatureBox 
          rephrase="Integrates with existing tools"
          title="Phonebanking your volunteers will love"
          description="After installing the CrowdCall chrome extension,
          phone numbers on websites like OpenVPB and VoteBuilder will be replaced with an easy to use call button"
        />
        <br />
        <br />
        <br />
        <br />
        <FeatureBox 
          rephrase="No wasted volunteer time"
          title="Make more phone calls"
          description="CrowdCall makes calls directly through the compter, so volunteers will never need to waste their time dialing phone numbers by hand. Plus, volunteers privacy is protected and volunteers will never have to deal with call-backs after volunteering."
        />
    </Col>
  </Row>
  <Row className="align-items-center" css={{marginTop: "120px"}}>
    <Col xs={{span: 10, offset: 1}} md={{span: 5, offset: 2}}>
      <FeatureBox 
          rephrase="Local area codes and virtual receptionist"
          title="Reach more voters"
          description="CrowdCall has local phone numbers in almost every area code in the country, so voters will always get calls from area code they know. Plus, if they call back, they will be directed to a configurable campaign receptionist that can redirect calls and take messages."
        />
    </Col>
    <Col className="d-none d-md-block" md={4}>
      <img alt="Voters making calls during a phonebank" src="/images/voters-calling.svg" css={{width: '100%'}} />
    </Col>
  </Row>
  <Row className="align-items-center" css={{marginTop: "120px"}}>
    <Col className="d-none d-md-block" md={{span: 4, offset: 2}}>
      <img alt="A piggy bank" src="/images/save-money.svg" css={{width: '100%'}} />
    </Col>
    <Col className="align-middle" xs={{span: 10, offset: 1}} md={{span: 5}}>
      <FeatureBox 
          rephrase="The best prices in the world, guaranteed"
          title="Save Money"
          description={
            <><span>CrowdCall was built with affordability in mind. Our innovative call-routing technology allows us to offer cheaper rates than any other phone provider in the world.</span><br /><br /><Link to="/pricing">See how much CrowdCall can save you →</Link></>
          }
        />
    </Col>
  </Row>

  <TiltedAccentTitle
            title="Our mission is to help progressive campaigns succeed"
            subtitle="We don’t see our users as a revenue source — we see them as potential progressives in office. This mission-driven mindset means that we are wholly committed to each of our partners from announcement day to election day, regardless of their size or volume.">
            <Link to="/mission"><Button variant="light">Learn More</Button></Link>
  </TiltedAccentTitle>

  <TestimonialAd />

  <ContactFormWrapper />
  
  <Footer />

</CrowdCallContainer>
