import React from 'react';
import { Form, Button } from 'react-bootstrap';

export const ContactForm = () => <div>
  <form action="https://formspree.io/support@crowdcall.us" method="POST">
    <Form.Group controlId="name">
      <Form.Label>Full Name</Form.Label>
      <Form.Control required name="name" feedback="You must enter your name before submitting." placeholder="John Smith" />
    </Form.Group>

    <Form.Group controlId="email">
      <Form.Label>Email address</Form.Label>
      <Form.Control required name="email" feedback="You must enter your email before submitting." type="email" placeholder="john.smith@adambaker2035.org" />
      <Form.Text className="text-muted">
        We need your email so we can schedule a time to follow up. We'll never share your email with anyone else.
    </Form.Text>
    </Form.Group>

    <Form.Group controlId="organization">
      <Form.Label>Campaign or organization</Form.Label>
      <Form.Control required name="organization" feedback="You must enter a campaign or organization before submitting." placeholder="Adam Baker Campaign, New York Governor" />
      <Form.Text className="text-muted">
        What political campaign or organization do you work with? If you are independent, state so.
      </Form.Text>
    </Form.Group>

    <Form.Group controlId="message">
      <Form.Label>Message</Form.Label>
      <Form.Control name="message" placeholder="" />
      <Form.Text className="text-muted">
        You may optionally include a message
      </Form.Text>
    </Form.Group>

    <Button variant="primary" type="submit">
      Submit
    </Button>
  </form>
</div>