
import React from "react"
import { Helmet } from "react-helmet";
import "./global.scss";
import FullStory from 'react-fullstory';

export const CrowdCallContainer = (props) => <>
  <Helmet title="CrowdCall - Phonebanking Tools">
    <FullStory org="EQ7YB" />
    <meta name="description" content="CrowdCall is a phonebanking tool for progressive campaigns which integrates with Votebuilder and OpenVPB." />
  </Helmet>
  {props.children}
</>